import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const chartData = JSON.parse(this.data.get("data"))
    const chartLabels = JSON.parse(this.data.get("labels"))

    let ctx = this.element

    let myBarChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: chartLabels,
        datasets: [{
          label: 'Applications',
          data: chartData,
          backgroundColor: "#33ace1"
        }]
      },
      options: {
        legend: { display: false },
        title: {
          display: true,
          text: "Application History"
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              stepSize: 1
            }
          }]
        }
      }
    })
  }
}
