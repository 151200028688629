import { InputController } from "../input_controller"

export default class extends InputController {
  static targets = ["disabledUnlessComplete"]

  connect() {
    super.connect()

    const controller = this
    const requiredElements = Array.
      from(this.element.querySelectorAll("input:required, select:required, textarea:required"))

    requiredElements.forEach(el => {
      // we'll listen for the change event for radios
      if (el.type === "radio") {
        el.addEventListener("change", function() {
          controller.updateDisabledButtons()
        })
      // otherwise we'll listen for the input event
      } else {
        el.addEventListener("input", function() {
          controller.updateDisabledButtons()
        })
      }
    })
    this.updateDisabledButtons()
  }

  updateDisabledButtons() {
    const requiredElements = Array.
      from(this.element.querySelectorAll("input:required, select:required, textarea:required"))

    let elementCount = requiredElements.length

    // radios get counted twice, need to account for that
    let offset = (requiredElements.filter(el => el.type === "radio").length / 2)
    elementCount = elementCount - offset

    let completedCount = 0

    requiredElements.forEach(el => {
      // handle radio buttons separately
      if ((el.type === "radio" && el.checked) || (el.type !== "radio" && el.value)) {
        completedCount++
      }
    })

    this.disabledUnlessCompleteTargets.forEach(el => el.disabled = (elementCount != completedCount))
  }
}
