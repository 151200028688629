import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["start", "end"]

  connect() {
    this.enforceMaxDate = true

    // used by `Activity` date range. may have to re-examine if this becomes more of the norm than
    // the exception, but currently most of our date ranges are on the application form and we *do*
    // want to enforce the max date
    if (this.data.has("do-not-enforce-max-date") && this.data.get("do-not-enforce-max-date") === "true") {
      this.enforceMaxDate = false
    }

    // if we are enforcing a max date, we'll use today's date
    let maxDate = this.enforceMaxDate ? new Date() : null

    flatpickr(this.startTarget, { maxDate: maxDate })
    flatpickr(this.endTarget,   { maxDate: maxDate })
  }

  updateDatepickers(event) {
    const changed = event.target

    // if the change event was deleting the selected date, handle that by removing relevant min/max dates
    if (changed.value === "") {
      if (changed === this.startTarget) {
        // remove the minDate from the end date, but keep selected date in tact
        let selectedDate = flatpickr.parseDate(this.endTarget.value)
        let maxDate = this.enforceMaxDate ? new Date() : null
        flatpickr(this.endTarget, { defaultDate: selectedDate, maxDate: maxDate })
      } else if (changed === this.endTarget) {
        // remove the maxDate from the start date, but keep selected date in tact
        let selectedDate = flatpickr.parseDate(this.startTarget.value)
        let maxDate = this.enforceMaxDate ? new Date() : null
        flatpickr(this.startTarget, { defaultDate: selectedDate, maxDate: maxDate })
      }
      return
    }

    // otherwise, a date was selected. lets update the other datepicker accordingly
    if (changed === this.startTarget) {
      // we'll set minDate on the end date to be the selected start date
      let minDate = flatpickr.parseDate(changed.value)

      // have to also make sure we dont lose the selected date on the end date (if there is one)
      let selectedDate = flatpickr.parseDate(this.endTarget.value)
      let maxDate = this.enforceMaxDate ? new Date() : null
      flatpickr(this.endTarget, { defaultDate: selectedDate, minDate: minDate, maxDate: maxDate })
    } else if (changed === this.endTarget) {
      // we'll set maxDate on the start date to be the selected end date
      let maxDate = flatpickr.parseDate(changed.value)

      if (!this.enforceMaxDate) {
        maxDate = null
      }

      // have to also make sure we dont lose the selected date on the start date (if there is one)
      let selectedDate = flatpickr.parseDate(this.startTarget.value)
      flatpickr(this.startTarget, { defaultDate: selectedDate, maxDate: maxDate })
    }
  }
}
