import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.isOpen = true
    const controller = this

    // automatically dismiss notification after set duration
    setTimeout(function() {
      // check that the notification hasn't already been dismissed
      if (controller.isOpen) {
        controller.dismiss()
      }
    }, controller.duration)
  }

  dismiss() {
    this.element.parentNode.removeChild(this.element)
    this.isOpen = false
  }

  get duration() {
    return 3000
  }
}
