import { RadioController } from "../radio_controller"

export default class extends RadioController {
  static targets = ["details"]

  update(event) {
    // update the button as normal
    super.update(event)

    const clickedButton = event.target

    if (clickedButton.dataset["buttonFor"] === "true") {
      // reveal details textarea
      this.detailsTarget.classList.remove("is-hidden")
    } else {
      // hide and clear details textarea
      this.detailsTarget.classList.add("is-hidden")
      this.detailsTarget.querySelector("textarea").value = ""
    }
  }
}
