import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "actions"]

  connect() {
    // do this on connect to ensure the page looks right when hitting back button
    this.checkboxTargets.forEach((el, i) => {
      this.updateSelectedClass(el)
    })
    this.showOrHideActions()
  }

  toggle(event) {
    this.updateSelectedClass(event.target)
    this.showOrHideActions()
  }

  toggleAll() {
    const controller = this
    const atLeastOneUnchecked = this.checkboxTargets.find(checkbox => checkbox.checked === false)

    // if even one is left unchecked, lets select all
    if (atLeastOneUnchecked) {
      this.checkboxTargets.filter(checkbox => checkbox.checked === false).forEach(el => {
        el.checked = true
        controller.updateSelectedClass(el)
      })
    // otherwise all are checked. deselect all
    } else {
      this.checkboxTargets.forEach(el => {
        el.checked = false
        controller.updateSelectedClass(el)
      })
    }
    controller.showOrHideActions()
  }

  addUsersAndSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    const form = event.target

    // remove any previously added inputs
    Array.from(form.querySelectorAll("input[name='user_ids[]']")).
      forEach(el => { el.parentNode.removeChild(el) })

    this.checkboxTargets.filter(checkbox => checkbox.checked === true).forEach(el => {
      let input = document.createElement("input")
      input.setAttribute("type", "hidden")
      input.setAttribute("name", "user_ids[]")
      input.setAttribute("value", el.dataset['userId'])
      form.appendChild(input)
    })

    form.submit()
  }

  cancel() {
    this.checkboxTargets.forEach((el, i) => {
      el.checked = false
      this.updateSelectedClass(el)
    })
    this.showOrHideActions()
  }

  updateSelectedClass(el) {
    if (el.checked) {
      el.closest("tr").classList.add("is-selected")
    } else {
      el.closest("tr").classList.remove("is-selected")
    }
  }

  showOrHideActions() {
    const atLeastOneChecked = this.checkboxTargets.find(checkbox => checkbox.checked === true)
    if (atLeastOneChecked) {
      if (this.hasActionsTarget) {
        this.actionsTarget.classList.remove("invisible")
      }
    } else {
      if (this.hasActionsTarget) {
        this.actionsTarget.classList.add("invisible")
      }
    }
  }
}
