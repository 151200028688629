// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
window.Rails = Rails
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// ---------- import "core-js" and "regenerator-runtime" to support IE 11 --------------
// These replace the deprecated "babel-polyfill" package (See https://babeljs.io/docs/en/babel-polyfill/)
import "core-js/stable";
import "regenerator-runtime/runtime";

// --------- order matters --------
require(`script-loader!sprinkles/polyfills.js`);

// --------- order doesn't matter -------
require(`script-loader!sprinkles/disable_with_class_rails_ujs_monkey_patch.js`)
require(`script-loader!sprinkles/handle_errors_for_remote_forms.js`)

import "controllers"

import flatpickr from "flatpickr"
import Chart from 'chart.js'
