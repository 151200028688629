import { Controller } from "stimulus"

export class RadioController extends Controller {
  static targets = ["button"]

  connect() {
    const checked = this.element.querySelector("input[type='radio']:checked")

    // if we have a checked radio, make sure its button has the "selected" class
    if (checked) {
      this.buttonForRadio(checked).classList.add(this.selectedClass)
    }
  }

  update(event) {
    const controller = this
    const clickedButton = event.target
    if (this.needsUpdate(clickedButton)) {
      this.radioForButton(clickedButton).checked = true
      // fire off the "change" event that would normally be triggered by updating the radio button
      let e = new Event('change')
      this.radioForButton(clickedButton).dispatchEvent(e)

      Array.from(this.otherRadios(clickedButton)).forEach(el => { el.checked = false })

      this.buttonTargets.forEach(el => {
        if (el === clickedButton) {
          el.classList.add(controller.selectedClass)
        } else {
          el.classList.remove(controller.selectedClass)
        }
      })
    }
  }

  needsUpdate(button) {
    return !this.radioForButton(button).checked
  }

  radioForButton(button) {
    return this.element.querySelector(`input[type='radio'][value='${button.dataset['buttonFor']}']`)
  }

  otherRadios(button) {
    return this.element.querySelectorAll(`input[type='radio']:not([value='${button.dataset['buttonFor']}'])`)
  }

  buttonForRadio(radio) {
    return this.buttonTargets.find(el => el.dataset['buttonFor'] === radio.value)
  }

  get selectedClass() {
    return "is-link"
  }
}

// allows us to extend this controller with hub/criminal_record_controller
export default RadioController
