import { Controller } from "stimulus"
import SignaturePad from "signature_pad/dist/signature_pad"

export default class extends Controller {
  static targets = ["canvasField", "inputField", "confirmCheckbox"]

  connect() {
    const canvas = this.canvasFieldTarget
    this.loadCanvas(canvas)
    this.addSubmitListener()
  }

  // Helper function to resize the canvas
  resizeCanvas(canvas) {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext("2d").scale(ratio, ratio)
  }

  // Loads the canvas when the user navigates to the signature page
  loadCanvas(canvas) {
    canvas.height = canvas.offsetHeight
    canvas.width = canvas.offsetWidth
    window.onresize = this.resizeCanvas(canvas)
    this.resizeCanvas(canvas)
    this.signature_pad = new SignaturePad(canvas)

    // If the user already saved his/her signature, retrieve it from the database and display it.
    if (this.inputFieldTarget.value) {
      this.signature_pad.fromDataURL(this.inputFieldTarget.value)
    }
  }

  // Clears the canvas
  clearCanvas() {
    this.signature_pad.clear()
  }

  addSubmitListener() {
    const controller = this

    const form = this.element

    form.addEventListener("submit", function(event) {
      event.preventDefault()
      event.stopPropagation()

      if (controller.signature_pad.isEmpty()) {
        // If the user hasn't drawn his/her signature, show an alert
        alert(controller.data.get("draw-signature-required-message"))
      }
      else if (!controller.confirmCheckboxTarget.checked) {
        // If the user hasn't checked the confirm signature checkbox, show a different alert
        alert(controller.data.get("confirm-signature-required-message"))
      }
      else {
        // Otherwise, set the hidden input field's value to the user's signature and submit the
        // form as normal
        controller.inputFieldTarget.value = controller.signature_pad.toDataURL()
        form.submit()
      }
    })
  }
}
