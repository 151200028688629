// handles revealing/hiding the follow up field depending on the "discovery method" selection

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["detailsField", "detailsLabel", "detailsInput"]

  connect() {
    const checked = this.element.
      querySelector("input[type='radio'][name='discovery_method_response[discovery_method]']:checked")
    if (checked) {
      this.updateDetailsVisibility(checked.value)
    }
  }

  update(event) {
    const field = event.target.dataset["buttonFor"]
    this.updateDetailsVisibility(field)
  }

  updateDetailsVisibility(field) {
    // clear the input every time. this ensures we dont submit a value when we shouldnt, and also
    // that the field appears as a new field when moving between "job site" and "friend"
    this.detailsInputTarget.value = ''

    // check to see if the button corresponded to a field we want to reveal
    if (field === "job_site" || field === "friend") {
      this.detailsFieldTarget.classList.remove("is-hidden")

      this.detailsLabelTargets.forEach(el => {
        if (el.dataset["labelFor"] === field) {
          el.classList.remove("is-hidden")
        } else {
          el.classList.add("is-hidden")
        }
      })
    } else {
      // another option was selected, hide the details field and both labels
      this.detailsFieldTarget.classList.add("is-hidden")
      this.detailsLabelTargets.forEach(el => el.classList.add("is-hidden"))
    }
  }
}
