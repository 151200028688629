import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu"]

  toggle(event) {
    event.target.classList.toggle("is-active")
    this.menuTarget.classList.toggle("is-active")
  }
}
