// for this page, we want to show the date fields only if certain options are selected for veteran status.
// if the date fields are shown, we want to make them "required" (throw a JS alert if they're blank).

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dateFields"]

  connect() {
    const controller = this
    const checked = this.element.querySelector("input[name='equal_opportunity_response[veteran_status]']:checked")

    if (checked && checked.value !== "not_a_veteran" && checked.value !== "decline_veteran") {
      this.showDateFields()
    } else {
      this.hideDateFields()
    }

    this.element.addEventListener("submit", function(event) {
      event.preventDefault()
      event.stopPropagation()

      // if we're showing date fields, make sure they have values
      if (controller.state === "open") {

        const hasValue = Array.from(
            controller.dateFieldsTarget.querySelectorAll("input:not(.flatpickr-mobile)")
          ).filter(el => el.value)
        if (hasValue.length !== 2) {
          alert(controller.data.get("required-dates-message"))
        } else {
          this.submit()
        }
      } else {
        this.submit()
      }
    })
  }

  update(event) {
    const radio = this.radioForButton(event.target)
    if (radio.value === "not_a_veteran" || radio.value === "decline_veteran") {
      this.hideDateFields()
      // clear the date inputs
      Array.from(this.dateFieldsTarget.querySelectorAll("input")).forEach(el => {
        el.value = ""
      })
    } else {
      this.showDateFields()
    }
  }

  showDateFields() {
    this.dateFieldsTarget.classList.remove("is-hidden")
    this.state = "open"
  }

  hideDateFields() {
    this.dateFieldsTarget.classList.add("is-hidden")
    this.state = "closed"
  }

  radioForButton(button) {
    return this.element.querySelector(`input[type='radio'][value='${button.dataset['buttonFor']}']`)
  }
}
