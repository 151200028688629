// used on languages step of application and on employee page in hub

import { MultiSelectController } from "./multi_select_controller"

export default class extends MultiSelectController {
  static targets = ["otherButton", "textField"]
  connect() {
    super.connect()
    if (this.otherButtonTarget.classList.contains(super.selectedClass)) {
      this.showTextField()
    }
  }

  toggle(event) {
    super.toggle(event)
    const clickedButton = event.target
    const checkbox = super.checkboxForButton(clickedButton)

    if (clickedButton === this.otherButtonTarget) {
      if (checkbox.checked) {
        this.showTextField()
      } else {
        this.hideTextField()
      }
    }
  }

  showTextField() {
    this.textFieldTarget.classList.remove("is-hidden")
    this.textFieldTarget.querySelector("input").setAttribute("required", "required")
  }

  hideTextField() {
    this.textFieldTarget.classList.add("is-hidden")
    this.textFieldTarget.querySelector("input").removeAttribute("required")
  }
}
