import { Controller } from "stimulus"
import Inputmask from "inputmask"

export class InputController extends Controller {
  static targets = ["phone", "social", "currency", "revealButton", "zip"]

  connect() {
    this.phoneTargets.forEach(el => {
      Inputmask("(999) 999-9999", { showMaskOnHover: false, showMaskOnFocus: false }).mask(el)
    })

    this.currencyTargets.forEach(el => {
      Inputmask("9{+}.99", { showMaskOnHover: false, placeholder: "0", showMaskOnFocus: false }).mask(el)
    })

    if (this.hasSocialTarget) {
      this.updateRevealButton()
    }

    this.zipTargets.forEach(el => {
      Inputmask("99999", { showMaskOnHover: false, showMaskOnFocus: false }).mask(el)
    })
  }

  toggleSocialVisibility() {
    this.socialTarget.classList.toggle("secure")
    const icon = this.revealButtonTarget.querySelector("i")
    if (icon.classList.contains("fa-eye-slash")) {
      icon.classList.remove("fa-eye-slash")
      icon.classList.add("fa-eye")
    } else {
      icon.classList.remove("fa-eye")
      icon.classList.add("fa-eye-slash")
    }
  }

  restrictToDigits(event) {
    // a character was added and it's not a digit
    if (event.data && !(/[0-9]/.test(event.data))) {
      const element = event.target
      let value = element.value
      // remove the last character
      value = value.substring(0, element.value.length -1)
      element.value = value
    }
  }

  updateRevealButton() {
    const input = this.socialTarget
    if (input.value.length > 0) {
      this.revealButtonTarget.classList.remove("is-static")
    } else {
      this.revealButtonTarget.classList.add("is-static")
    }
  }
}
export default InputController
