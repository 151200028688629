import { Controller } from "stimulus"

export default class extends Controller {
  confirm(event) {
    const message = this.data.get("confirmMessage")
    if (!confirm(message)) {
      event.stopPropagation()
      event.preventDefault()
    }
  }
}
