import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source", "reason", "contactMethodField", "callOutFields"]

  connect() {
    const controller = this
    this.updateReasons()
    this.updateContactMethodField()

    this.element.addEventListener("submit", function(event) {
      if (!controller.forceSubmit) {
        event.preventDefault()
        event.stopPropagation()

        // if we're making an outbound call, make sure we've selected a contact method
        if (controller.makingOutbound) {
          if (!controller.hasSelectedContactMethod()) {
            alert("Please select a contact method")
          } else {
            // we have to trigger the submit this way to still get the form's `remote: true` functionality.
            // however, doing so will also re-run the logic in this event listener, causing an infinite
            // loop. So we set this variable to break the cycle.
            controller.forceSubmit = true
            Rails.fire(this, 'submit')
          }
        } else if (controller.callingOut) {
          // if we're showing the additional call out fields, make sure they're all filled out
          if (!controller.hasSelectedStartDate()) {
            alert("Please select a start date")
          } else if (!controller.hasSelectedEndDate()) {
            alert("Please select an end date")
          } else {
            controller.forceSubmit = true
            Rails.fire(this, 'submit')
          }
        } else {
          controller.forceSubmit = true
          Rails.fire(this, 'submit')
        }
      }
    })
  }

  updateReasons() {
    let options = []
    if (["inbound", "on_site"].includes(this.sourceTarget.value)) {
      options = this.inboundReasons().map(el => {
        return `<option value='${el[0]}'>${el[1]}</option>`
      })
    } else {
      options = this.outboundReasons().map(el => {
        return `<option value='${el[0]}'>${el[1]}</option>`
      })
    }
    this.reasonTarget.innerHTML = options.join('')
    // need to update other fields as well that pertain to the selected reason
    this.updateCallOutFields()
  }

  updateContactMethodField() {
    if (this.sourceTarget.value === "outbound") {
      this.showContactMethodField()
    } else {
      this.hideContactMethodField()
    }
  }

  showContactMethodField() {
    this.contactMethodFieldTarget.classList.remove("is-hidden")
    this.makingOutbound = true
  }

  hideContactMethodField() {
    this.contactMethodFieldTarget.classList.add("is-hidden")
    this.makingOutbound = false
  }

  hasSelectedContactMethod() {
    return this.element.querySelector("[name='activity[contact_method]']:checked") != null
  }

  updateCallOutFields() {
    if (this.reasonTarget.value === "call_out") {
      this.showCallOutFields()
    } else {
      this.hideCallOutFields()
    }
  }

  showCallOutFields() {
    this.callOutFieldsTarget.classList.remove("is-hidden")
    this.callingOut = true
  }

  hideCallOutFields() {
    this.callOutFieldsTarget.classList.add("is-hidden")
    this.callingOut = false
  }

  hasSelectedStartDate() {
    return this.element.querySelector("[name='activity[start_date]']").value !== ""
  }

  hasSelectedEndDate() {
    return this.element.querySelector("[name='activity[end_date]']").value !== ""
  }

  inboundReasons() {
    return [["work_check", "Work Check"], ["call_out", "Call Out"], ["general", "General Issue"]]
  }

  outboundReasons() {
    return [["make_offer", "Make Offer"], ["return_call", "Return Call"],
            ["schedule_interview", "Schedule Interview"], ["screening", "Screening"]]
  }
}
