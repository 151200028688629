import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "icon"]

  toggle(e) {
    e.target.classList.toggle("collapsed")
    this.contentTarget.classList.toggle("is-hidden")

    if (this.contentTarget.classList.contains("is-hidden")) {
      this.iconTarget.innerHTML = this.upArrowIcon
    } else {
      this.iconTarget.innerHTML = this.downArrowIcon
    }
  }

  get upArrowIcon() {
    return "<i class='fas fa-angle-up'></i>"
  }

  get downArrowIcon() {
    return "<i class='fas fa-angle-down'></i>"
  }
}
