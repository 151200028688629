// to make a multiselect 'required', give it a required message:
// data-multi-select-required-message="..."

import { Controller } from "stimulus"

export class MultiSelectController extends Controller {
  static targets = ["button"]

  connect() {
    const controller = this

    // if we have any checked checkboxes, make sure their buttons have the "selected" class
    Array.from(this.element.querySelectorAll("input[type='checkbox']:checked")).forEach(el => {
      this.buttonForCheckbox(el).classList.add(this.selectedClass)
    })

    if (this.data.has("required-message")) {
      const form = this.element.closest("form")

      form.addEventListener("submit", function(event) {
        event.preventDefault()
        event.stopPropagation()

        // If none of our checkbox elements are checked, show the alert
        if (!controller.element.querySelector("input[type='checkbox']:checked")) {
          alert(controller.data.get("required-message"))
        } else {
          // otherwise, submit the form as normal
          form.submit()
        }
      })
    }
  }

  toggle(event) {
    const clickedButton = event.target
    const checkbox = this.checkboxForButton(clickedButton)

    clickedButton.classList.toggle(this.selectedClass)
    checkbox.checked = !checkbox.checked
  }

  checkboxForButton(button) {
    return this.element.querySelector(`input[type='checkbox'][value='${button.dataset['buttonFor']}']`)
  }

  buttonForCheckbox(checkbox) {
    return this.buttonTargets.find(button => button.dataset['buttonFor'] === checkbox.value)
  }

  get selectedClass() {
    return "is-link"
  }
}
// TODO: dont fully understand why this is needed/whats going on here... might be good to
// look into this more at some point
export default MultiSelectController
